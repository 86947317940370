// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {TemplateProduct} from "../model.product/TemplateProduct";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'An Post: Post Office',
  filename: 'environment.post-office-80798384.ts',


  isDevelopment: false,
  isProduction: true,
  isTest: false,


  authenticationConfig: {

    emailLink: false,
    // microsoft: {
    //   clientId: "98e2543e-b602-4771-a509-66d0bfcfcf22",
    //   tenantId: "3872f7c4-cb94-413b-93f5-874b9feb4674",
    //   companyName: "Mobility Mojo",
    // },
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    databaseURL: "https://facilities-80798384-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-80798384",
    storageBucket: "facilities-80798384.appspot.com",

    apiKey: "AIzaSyBhOXbNFzb0KoJ8VaBkasiKSpuxXGUxVjs",
    authDomain: "facilities-80798384.firebaseapp.com",
    messagingSenderId: "590581802806",
    appId: "1:590581802806:web:234f1f0700ed81f8ecb597"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-80798384.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8081',
    httpServer: 'https://next.mobilitymojo-spot.com',
    user: 'api-viewer@mobilitymojo.com',
    password: 'IxqWxN;A<s2b=(H',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: 'Post Office - 2024',
  },


  productConfig: {

    // product:  ProductHelper.cloneProduct( new FacilityAccessibilityProduct(), true )
    // product:  BankingAccessibilityProduct.INSTANCE,
    // product:  TemplateProduct.INSTANCE,
    // product:  FacilityAccessibilityProduct4.INSTANCE,

    // firebaseAnswersRoot: '/answers-x/banking',
    // productType: EProductType.banking,
    // product: TemplateProduct.INSTANCE,

    firebaseAnswersRoot: '/answers-x/post-office',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.postOffice,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,


    // firebaseAnswersRoot: '/answers-x/manufacturing',
    // product:  FacilityAccessibilityProduct4.INSTANCE,
    // productType: EProductType.manufacturing,
    // canCommentOnClusters: true,
    // canEditClusters: false,



  },


  preview: {

    fireworksEnabled: true,
  },


};
