


export class BrowserAppEvaluationToolInfo {

  public version = '2024.6.12.1';

  constructor() {
  }

}
